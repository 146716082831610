import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Form from '../Form'
import * as S from './styled'

const Contact = () => {
  const { contact } = useStaticQuery(
    graphql`
      query {
        contact: markdownRemark(frontmatter: { key: { eq: "images" } }) {
          frontmatter {
            contact {
              childImageSharp {
                fluid(maxWidth: 960, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  const image = contact.frontmatter.contact.childImageSharp.fluid
  return (
    <S.ContactWrapper id="contato">
      <S.Image fluid={image} />
      <S.FormWrapper>
        <S.Title>Contato</S.Title>
        <div className="border" />
        <Form />
      </S.FormWrapper>
    </S.ContactWrapper>
  )
}

export default Contact
