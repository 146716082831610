import styled from 'styled-components'
import media from 'styled-media-query'
import BackgroundImage from 'gatsby-background-image'

export const ContactWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  `}
`

export const Image = styled(BackgroundImage)``

export const FormWrapper = styled.div`
  padding: 10%;

  ${media.lessThan('medium')`
    order: -1;
    padding: 10% 20px;
  `}
`

export const Title = styled.h2``
