import React, { Component } from 'react'

import * as S from './styled'

class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message: null
    }
  }
  async handleSubmit(event) {
    event.preventDefault()
    const { target: form } = event
    const data = {
      name: form.name.value,
      subject: form.subject.value,
      email: form.email.value,
      message: form.message.value
    }

    const response = await (
      await fetch(form.action, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(data)
      })
    ).json()
    this.setState({
      message: response.message
    })
    console.log(response)
  }

  render() {
    const { message } = this.state

    return (
      <form
        action="/.netlify/functions/contact"
        onSubmit={this.handleSubmit.bind(this)}
      >
        <S.InputGroup>
          <S.Input
            name="name"
            type="text"
            aria-label="Nome"
            placeholder="Nome"
            required
          />
        </S.InputGroup>
        <S.InputGroup>
          <S.Input
            name="email"
            type="email"
            aria-label="Email"
            placeholder="Email"
            required
          />
        </S.InputGroup>
        <S.InputGroup>
          <S.Input
            name="subject"
            type="text"
            aria-label="Assunto"
            placeholder="Assunto"
            required
          />
        </S.InputGroup>
        <S.InputGroup>
          <S.Textarea
            name="message"
            aria-label="Mensagem"
            placeholder="Mensagem"
            required
          />
        </S.InputGroup>

        <button type="submit">Enviar</button>
        <div>
          {message ? (
            <div className="notification" role="alert">
              {message}
            </div>
          ) : (
            ''
          )}
        </div>
      </form>
    )
  }
}

export default Form
