import styled from 'styled-components'

export const InputGroup = styled.div`
  display: block;
  margin-bottom: 12px;
`

export const Input = styled.input`
  font-size: 16px;
  border-radius: 2;
  border: 1px solid #707070;
  padding: 8px;
  width: 100%;
`

export const Textarea = styled.textarea`
  font-size: 17px;
  font-family: inherit;
  border-radius: 2;
  border: 1px solid #707070;
  padding: 8px;
  width: 100%;
  height: 200px;
  resize: none;
`
